import NewMeetupForm from "../components/meetups/NewMeetupForm";
import { useNavigate } from 'react-router-dom'; // to navigate away after submitting the form


function NewMeetupPage() {

    let navigate = useNavigate();

    function addMeetupHandler(meetupData) {
        // when you add meetups.json > a meetups table gets created
        fetch(
            'https://project-x-36c32-default-rtdb.europe-west1.firebasedatabase.app/meetups.json',
            {
                method: 'POST',
                body: JSON.stringify(meetupData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(() => {
            //replace will navigate you away but don't allow you to use the back button
            navigate('/');
        });
    }

    return <section>
        <h1>Add new Meetup</h1>
        <NewMeetupForm onAddMeetup={addMeetupHandler} />
    </section>
}

export default NewMeetupPage;