import { useRef } from 'react'; // to get get the form values

import classes from './NewMeetupForm.module.css';
import Card from '../ui/Card';



function NewMeetupForm(props) {

    const titleInputRef = useRef();
    const imageInputRef = useRef();
    const addresInputRef = useRef();
    const descriptionInputRef = useRef();

    function submitHandler(e) {

        e.preventDefault();

        const enteredTitle = titleInputRef.current.value;
        const enteredImage = imageInputRef.current.value;
        const enteredAddres = addresInputRef.current.value;
        const enteredDescription = descriptionInputRef.current.value;

        const meetupData = {
            title: enteredTitle,
            image: enteredImage,
            address: enteredAddres,
            description: enteredDescription,
        }

        // project-x-36c32
        // https://console.firebase.google.com/project/project-x-36c32/database/project-x-36c32-default-rtdb/data/~2F

        // console.log(meetupData)

        props.onAddMeetup(meetupData);
    }

    return <Card>

        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='title'>Meetup Title</label>
                <input type='text' required id='title' ref={titleInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='image'>Meetup Image</label>
                <input type='url' required id='image' ref={imageInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='address'>Meetup Adress</label>
                <input type='text' required id='address' ref={addresInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='description'>Meetup Description</label>
                <textarea id='description' required row='5' ref={descriptionInputRef}></textarea>
            </div>
            <div className={classes.actions}>
                <button>Add Meetup</button>
            </div>

        </form>
    </Card>

}

export default NewMeetupForm;